/**
 * Inhaltsverzeichnis
 * 	1. 
 * 		1.1 Properties
 * 		1.2 Constructor
 * 		1.3 
 */
// ══════════════════════════════════════════════════
// MARK: 0. Setup
// ──────────────────────────────────────────────────
// #=#=#=#=#=# 0.1 Imports #=#=#=#=#=#
import BaseElement from '../../base/index.tsx';
import WorkerPool from '../../../ts/class/workerpool/index.ts';
import { CSSRuleSet } from '../../../ts/class/style/exportlist.ts';
import { debounce } from '../../../ts/module/timing.ts';
import GridLayout from '../assets/grid-layout/index.ts';
import stylesheet from './style.scss?inline';


// #=#=#=#=#=# 0.2 Types #=#=#=#=#=#
import type {
	GridAxis,
	WorkerRequest,
	WorkerResponse
} from '../assets/types.d.ts';
import type HTMLSubgridElement from '../subgrid/index.tsx';
import Sanitizer from '../../../ts/class/sanitizer/index.ts';


// ══════════════════════════════════════════════════
// MARK: 1. HTML Grid Element
// ──────────────────────────────────────────────────
export default class HTMLGridElement extends BaseElement {
	// #=#=#=#=#=# 1.1 Properties #=#=#=#=#=#
		// #════ Static ════#
	/** Webworkers */
	// static readonly workers:WorkerPool		=	new WorkerPool(
	// 	() => new Worker(new URL('../worker.ts', import.meta.url), {
	// 		type: 'module'
	// 	}),
	// 	6,
	// 	false,
	// 	3000
	// );
	/** The Stylesheet for the gloabl Element */
	static readonly _styles:CSSRuleSet		=	new CSSRuleSet(stylesheet);
	/** List of observed Attributes within the an axis */
	static readonly axisAttributes			=	[
		'count',
		'gap',
		'auto-flow',
		'track-pattern',
		'axis-length',
	];
	/** Attribute to Property Map */
	protected static readonly attributeMap	=	{
		'count':		'trackCount',
		'span':			'trackSpan',
		'axisLength':	'maxLength',
	};
	/**	The Resize Observer */
	// protected static readonly resizeObserver:ResizeObserver		=	new ResizeObserver(debounce((entries:ResizeObserverEntry[]) => {
	// 	entries.forEach((entry) => {
	// 		(entry.target as HTMLGridElement).updateTracks();
	// 	});
	// }, 100) as ResizeObserverCallback);


		// #════ Initialised ════#
	/** The layouting helper class instance that can be passed to the webworker */
	// protected readonly layout:GridLayout				=	new GridLayout(false, {
	// 	column: {
	// 		isDynamic: true,
	// 		trackCount: 4,
	// 	},
	// 	row: {
	// 		isDynamic: true,
	// 		trackCount: 0,
	// 	}
	// });
	/**	Debounced Update Function */
	// protected readonly updateTracks:Function		=	debounce(this.__unstableUpdateTracks.bind(this), 100);


		// #════ Uninitialized ════#


	//
	// MARK: Constructor
	//
	// #=#=#=#=#=# 1.2 Constructor #=#=#=#=#=#
	constructor(
		
	) {
		// #════ Parent ════#
		super();


		// #════ Properties ════#


		// #════ Actions ════#


		// #════ Observe Resize ════#
		// HTMLGridElement.resizeObserver.observe(this);
	}


	//
	// MARK: Worker
	//
	// #═#═#═#═#═# 1.? Webworker Change #═#═#═#═#═#
	// private webworkerChange(
	// ): void {	
	// 	// #════ Add Task ════#
	// 	const task:WorkerRequest	=	{
	// 		layouter: this.layout,
	// 		elementRect: this.getBoundingClientRect(),
	// 		screen: {
	// 			width: window.innerWidth,
	// 			height: window.innerHeight
	// 		}
	// 	};
	// 	const response	=	HTMLGridElement.workers.addTask(task);

	// 	response.then((response:WorkerResponse) => {
	// 		console.groupCollapsed('Webworker Response:');
	// 		console.log(response.layouter);
	// 		console.log(response.ruleset);
	// 		console.log(new CSSRuleSet(response.ruleset));
	// 		console.groupEnd();
	// 	});
	// }


	//
	// MARK: Lifecycle
	//
	// #═#═#═#═#═# 1.? Get Observed Attributes #═#═#═#═#═#
	/**
	 * Create the list of observed attributes
	 */
	static get observedAttributes(): string[] {
		const attributes:string[]	=	[];

		for(const axis of ['column', 'row'] as GridAxis[]) {
			for(const attribute of this.axisAttributes) {
				attributes.push(`${axis}-${attribute}`);
			}
		}

		return attributes;
	}


	// #═#═#═#═#═# 1.? Element Mount #═#═#═#═#═#
	/**
	 * Called when the element is mounted to the DOM
	 */
	// protected connectedCallback(
	// ): void {
	// 	// #════ Slot Assignment ════#
		


	// 	// #════ Guard ════#
	// 	if(this.isConnected) {
	// 		return;
	// 	}


	// 	// #════ Actions ════#
	// 	this.layout.defineElementBoundary(this.getBoundingClientRect());
	// }


	// #=#=#=#=#=# 1.? Changed Attributes #=#=#=#=#=#
	/**
	 * Handles changes to the observed attributes of the element
	 */
	public attributeChangedCallback(
		name:string,
		_:string,
		value:string|null
	):void {
		// REMOVE and rework
		// #════ TEMP ════#
		switch(name) {
			case 'column-count':
				if(value) {
					this._styles.setProperty(':host', '--column_count', value);
				}

				else {
					this._styles.removeProperty(':host', '--column_count');
				}
				break;

			case 'row-count':
				if(value) {
					this._styles.setProperty(':host', '--row_count', value);
				}

				else {
					this._styles.removeProperty(':host', '--row_count');
				}
				break;
		}


		// #════ Get Allocation ════#
		// const {axis, property}		=	GridLayout.parseAttribute(name);


		// #════ Guard ════#
		// if(!axis || !property) {
		// 	return;
		// }


		// #════ Property Mapping ════#
		// this.setLayout(axis, property, value);


		// #════ Update Loop ════#
		// this.updateTracks()
	}


	// #=#=#=#=#=# 1.? Track Update Loop #=#=#=#=#=#
	/**
	 * Update the Tracks of the Grid
	 */
	// protected __unstableUpdateTracks(
	// ): void {
	// 	// this.webworkerChange();
	// 	console.log(this.tagName, Object.getPrototypeOf(this).constructor.observedAttributes);
	// 	console.log(this.layout);

	// 	this.layout.update();
	// 	this._styles.replaceRules(`:host {${this.layout.getCSS()}}`);
	// }


	//
	// MARK: Layout Interface
	//
	// #═#═#═#═#═# 1.? Set Property #═#═#═#═#═#
	/**
	 * Sets a value for a property
	 * @param	axis		The axis to set the property for
	 * @param	property	The unmapped property to set
	 * @param	value		The value to set
	 */
	// public setLayout(
	// 	axis: GridAxis,
	// 	property: string,
	// 	value: any
	// ): void {
	// 	// Static Instance
	// 	const self	=	Object.getPrototypeOf(this).constructor as typeof HTMLGridElement|typeof HTMLSubgridElement;

	// 	// Get the mapped property
	// 	const mappedProperty	=	self.attributeMap[property] ?? property;

	// 	// Set the layout property
	// 	this.layout.setProperty(axis, mappedProperty, value);
	// }


	//
	// MARK: Track Helpers
	//
	// #═#═#═#═#═# 1.? Has Subdivisions #═#═#═#═#═#
	/**
	 * Check if the grid has subdivisions
	 * @param	axis	The axis to check for subdivisions
	 * @returns	true if the grid has subdivisions, false otherwise
	 */
	// public hasSubdivisions(
	// 	axis: GridAxis	=	'column'
	// ): boolean {
	// 	return (this.layout.getProperty(axis, 'trackPattern') as string[]).length > 1;
	// }


	// #=#=#=#=#=# 1.? Get Track Count #=#=#=#=#=#
	/**
	 * Get the number of tracks in an axis
	 * @param	instance		The grid element
	 * @param	axis			The axis to count the tracks for
	 * @param	subdivisions	Whether to count the subdivisions as well
	 * @returns The number of tracks if the element is a grid, otherwise 0
	 */
	// public static getAxisTrackCount(
	// 	instance: HTMLGridElement,
	// 	axis: GridAxis			=	'column',
	// 	subdivisions: boolean	=	false
	// ):number {
	// 	// #════ Guard ════#
	// 	if(!(instance instanceof HTMLGridElement)) {
	// 		return 0;
	// 	}


	// 	// #════ Properties ════#
	// 	const track =	instance.layout.getAxisProperties(axis);
	// 	let count: number;


	// 	// #════ Dynamic Layout ════#
	// 	if(track.current.isDynamic) {
	// 		const property		=	axis === 'column'? 'gridTemplateColumns' : 'gridTemplateRows';
	// 		const gridTemplate	=	window.getComputedStyle(instance)[property];
	// 		count	=	Sanitizer.presets.attributes.keywordList(gridTemplate).length;

	// 		if(!subdivisions) {
	// 			count	=	count / track.current.trackPattern.length;
	// 		}
	// 	}


	// 	// #════ Static Layout ════#
	// 	else{
	// 		count	=	track.evaluated.trackCount ?? 1;

	// 		if(subdivisions) {
	// 			count	=	count * track.current.trackPattern.length;
	// 		}
	// 	}


	// 	return count;
	// }


	// #═#═#═#═#═# 1.? Get Axis Track Count #═#═#═#═#═#
	/**
	 * Return the current number of tracks in an axis
	 * @param	axis			The axis to count the tracks for
	 * @param	subdivisions	Whether to count the subdivisions as well
	 */
	// public getAxisTrackCount(
	// 	axis: GridAxis			=	'column',
	// 	subdivisions: boolean	=	false
	// ): number {
	// 	return HTMLGridElement.getAxisTrackCount(this, axis, subdivisions);
	// }
}


// ══════════════════════════════════════════════════
// MARK: 2. Initialization
// ──────────────────────────────────────────────────
window.customElements.define('gw-grid', HTMLGridElement);