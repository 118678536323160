/**
 * Inhaltsverzeichnis
 * 	1. Subgrid Element
 * 		1.1 Properties
 * 		1.2 Constructor
 * 		1.3 
 */
// ══════════════════════════════════════════════════
// MARK: 0. Setup
// ──────────────────────────────────────────────────
// #=#=#=#=#=# 0.1 Imports #=#=#=#=#=#
import HTMLGridElement from '../container/index.tsx';
import { CSSRuleSet } from '../../../ts/class/style/exportlist.ts';
import GridLayout from '../assets/grid-layout/index.ts';
import stylesheet from './style.scss?inline';
import type {
	GridAxis,
	SubgridAxisProperties,
	SettableSubgridAxisProperties,
	CSSLength
} from '../types.d.ts';


// #=#=#=#=#=# 0.2 Types #=#=#=#=#=#


// ══════════════════════════════════════════════════
// MARK: 1. Subgrid Element
// ──────────────────────────────────────────────────
export default class HTMLSubgridElement extends HTMLGridElement {
	// #=#=#=#=#=# 1.1 Properties #=#=#=#=#=#
		// #════ Static ════#
	// static readonly selector:string			=	':host';

	static readonly _styles:CSSRuleSet		=	new CSSRuleSet(stylesheet);
	
	static readonly axisAttributes			=	[
		'count',
		'span',
		'gap',
		'auto-flow',
		'track-pattern',
		'axis-length',
	];
	
	// protected readonly layout:GridLayout				=	new GridLayout(true, {
	// 	column: {
	// 		isDynamic: true,
	// 		trackCount: 4,
	// 	},
	// 	row: {
	// 		isDynamic: true,
	// 		trackCount: 0,
	// 	}
	// });


		// #════ Initialised ════#


		// #════ Uninitialized ════#


	// #=#=#=#=#=# 1.2 Constructor #=#=#=#=#=#
	constructor(
		
	) {
		// #════ Parent ════#
		super();


		// #════ Properties ════#


		// #════ Actions ════#
	}


	// #=#=#=#=#=# 1.? Changed Attributes #=#=#=#=#=#
	// public attributeChangedCallback(
	// 	name:string,
	// 	_:string,
	// 	value:string
	// ):void {
		// #════ Set Properties ════#

	// }
}


// ══════════════════════════════════════════════════
// MARK: 2. Initialization
// ──────────────────────────────────────────────────
window.customElements.define('gw-subgrid', HTMLSubgridElement);