/**
 * Inhaltsverzeichnis
 * 	1. Base Element
 * 		1.1 Properties
 * 		1.2 Constructor
 * 		1.3 
 */
// ══════════════════════════════════════════════════
// MARK: 0. Setup
// ──────────────────────────────────────────────────
// #═#═#═#═#═# 0.1 Imports #═#═#═#═#═#
import { CSSRuleSet, CSSStyleSheetManager } from '../../ts/class/style/exportlist.ts';


// ══════════════════════════════════════════════════
// MARK: 1. Base Element
// ──────────────────────────────────────────────────
export default abstract class BaseElement extends HTMLElement {
	// #═#═#═#═#═# 1.1 Properties #═#═#═#═#═#
		// #════ Static ════#
	/** An Interface for all contained styles staticaly */
	static readonly _styles:CSSRuleSet;


		// #════ Initialised ════#
		/** ShadowRoot of the Element */
		public shadowRoot:ShadowRoot	=	this.attachShadow({
			mode: 'open',
			slotAssignment: 'manual'
		});
	/** An Interface for all contained styles localy */
	readonly _styles:CSSRuleSet					=	new CSSRuleSet();
	/**	Object containing the Ruleset for the Host Element */
	protected _ruleset:CSSStyleSheetManager		=	new CSSStyleSheetManager({
		static: Object.getPrototypeOf(this).constructor._styles,
		element: this._styles
	});
	/** Child Element Observer */
	private _nodeObserver:MutationObserver		=	new MutationObserver(this.assignSlots.bind(this));
	/** Contained Slot */
	private _slot!: HTMLSlotElement;


		// #════ Uninitialized ════#


	// #=#=#=#=#=# 1.2 Constructor #=#=#=#=#=#
	constructor(
		
	) {
		// #==== Parent ====#
		super();


		// #==== Properties ====#
		this.shadowRoot.adoptedStyleSheets		=	this._ruleset.list();


		// #==== Actions ====#
		this.build();		
		this._nodeObserver.observe(this, {
			childList: true
		});
	}


	// #═#═#═#═#═# 1.3 Shadow Root HTML #═#═#═#═#═#
	protected build(
	):void {
		this._slot	=	document.createElement('slot');
		this.shadowRoot.appendChild(this._slot);
	}


	//
	// MARK: Lifecycle
	//
	// #═#═#═#═#═# 1.? Connected Callback #═#═#═#═#═#
	/**
	 * Connected Callback
	 */
	public connectedCallback(
	):void {
		this.assignSlots();
	}


	//
	// MARK: Manual Slots
	//	
	// #═#═#═#═#═# 1.? Slot Asignment #═#═#═#═#═#
	/**
	 * Manually assign the slots.
	 */
	private assignSlots(): void {
		// #════ Prepare Storage ════#
		const nodes:Element[]			=	[];


		// #==== Collect Nodes ====#
		for(const node of this.children) {
			if(node instanceof HTMLStyleElement) {
				this.shadowRoot.appendChild(node);
			}

			else {
				nodes.push(node);
			}
		}


		// #════ Assign Slots ════#
		this._slot.assign(...nodes);
	}
}